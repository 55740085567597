<script setup>
const Software = ref(['LiDAR360','LiDAR360MLS','LiPowerline','LiGeoreference'])
const Service = ref(['Upgrade Service','Cloud Service','Professional Training'])
let lists = ref([])
const getNavigation = async () => {
    await nextTick();
  const res = await useFetch(constants.BASE_URL + api.getNavigation,
    { method: 'GET' })
  console.log(res, 'list77');
  lists.value = res?.data.value.data

  // lists.value.push(...res?.data?._rawValue?.data)
  console.log(lists.value,'list66');
}
getNavigation()
const goChilder = (item) => {
    console.log(item,'item');
    navigateTo('/product/'+ item.name);
}


const goVideo = (url) => {
  window.location.href =url
}
</script>

<template>
  <div class="footerBox-conter">
    <div class="title">Store</div>
    <div class='box'>
        <div class="box-k" :style="lists.length> 1 ? '':'width: 30%'">
            <div class="box-item" v-for="item in lists">
            <div class="box-item-title">{{item.name}}</div>
            <div class='item' v-for="item2 in item?.children">
                <div v-if="item2.children">
                    <div class="text" v-for="item3 in item2?.children" @click="goChilder(item3)">
                        {{ item3.name }}
                    </div>
                </div>
                <div v-else class="text">{{ item2.name }}</div>
            </div>
        </div>
        </div>

        <div class="box-k" :style="lists.length> 1 ? '':'width: 60%'">
            <div class="box-item">
            <div class="box-item-title">Payment</div>
            <div class='item-img'>
                <img  src='../../../assets/imgs/footer/paypal.png' />
            </div>
        </div>
        <div class="box-item">
            <div class="box-item-title">Subscribe</div>
            <div class="decs">Be the first to find out about our newest offerings and hottest deals, and what's new.</div>
            <div class="btn">
                <input type="text" placeholder="Your Email Address" />
                <button>></button>
            </div>
        </div>
        </div>
    </div>
    <div class="bottom">
        <div>
            <img class="image-log" src="../../../assets/imgs/footer/logo-button.png" alt=""/>
        </div>
        <div>
            <img @click="goVideo('https://www.linkedin.com/company/greenvalleyintl/?viewAsMember=true')"  class="image" src="../../../assets/imgs/footer/icon_linkedin.png" alt=""/>
            <img @click="goVideo('https://www.facebook.com/GreenValleyINTL')" class="image" src="../../../assets/imgs/footer/icon_facebook.png" alt=""/>
            <img @click="goVideo('https://www.youtube.com/@GreenValleyINTL')" class="image" src="../../../assets/imgs/footer/icon_youtube.png" alt=""/>
            <img @click="goVideo('https://www.instagram.com/greenvalleyintl/')" class="image" src="../../../assets/imgs/footer/icon_instagram.png" alt=""/>
        </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.footerBox-conter {
  max-width: 1200px;
  margin: 0 auto;
  background: rgb(0, 0, 0);
  input {
        outline: none;
    }

    .title {
        padding: 20px 51px 30px 51px;
        color: #fff;
        font-size: 20px;
    }
    .box {
        display: flex;
        justify-content: space-between;
        padding: 0 70px;
        .box-k{
            width: 40%;display: flex;justify-content: space-between;
            .box-item {
            .box-item-title {
                color: #fff;
                font-size: 16px;
            }
            .decs {
              width: 250px;
              color: #BBBBBB;
              font-size: 14px;
              line-height: 18px;
              margin: 14px 0;  
            }
            .btn {
                display: flex;
                align-items: center;
                input {
                    width: 194px;
                    height: 41px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    border: none;
                    background: #444444;
                    color: #BBBBBB;
                    font-size: 14px;
                    line-height: 18px;
                    padding-left: 10px;
                }
                button {
                    width: 41px;
                    height: 41px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border: none;
                    background: linear-gradient(to right, #0189BE, #32A395);
                    color: #ffffff;
                    font-size: 14px;
                    line-height: 18px;
                    cursor: pointer;

                }
            }
            .item {
                text-align: left;
                .text {
                    color: #BBBBBB;
                    margin-top: 14px;
                    font-size: 14px;
                    line-height: 18px;
                    cursor: pointer;
                }
            }
            .item-img {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                margin-top: 14px;
                img {
                    width: 39px;
                    height: 26px;
                    margin-top: 10px;
                }   
            }
        }
        }


    }
    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 79px;
        margin-left: 7px;
       .text {
            color: #BBBBBB;
            font-size: 14px;
            line-height: 18px;
        }
        .image-log {
            width: 121px;
            height: 54px;
        }
        .image {
            margin-left: 10px;
            width: 33px;
            height: 32px;
            cursor: pointer;
        }
    }
}
</style>
