<script setup>
import config from "~/public/config.json";
const openPDF = (data) => {
  window.open(data)
}
</script>

<template>
  <div class="footerBox-btn">
    <div class="tle">Copyright © 2024 GreenValley International All Rights Reserved.</div>
    <div >
        <span @click="openPDF(config.PrivacyPolicy)">Privacy Policy</span>·
        <span @click="openPDF(config.CookiePolicy)">Cookie Policy</span>·
        <span @click="openPDF(config.TermsofUse)">Terms of Use</span>·
        <span @click="openPDF(config.ProductTerms)">Product Terms</span>
    </div>
  </div>
</template>

<style lang="less" scoped>
.footerBox-btn {
  max-width: 1200px;
  background: rgb(0, 0, 0);
  color: #888888;
  display: flex;
  align-items: center;
  padding: 7px 0 27px 0;
  font-size: 16px;
  .tle {
    margin-right: 55px;
  }
  span {
    margin: 0 10px;
    cursor: pointer;
  }
}
</style>
