<script setup>
import {
  defineComponent,
  defineProps,
  ref,
  reactive,
  computed,
  onMounted,
  onUnmounted,
} from "vue";

const props = defineProps(["list", "moduleTitle", "moduleDescribe"]);
let state = reactive({
  lists: []
});
const boxref = ref(null);

const getBestSell = async () => {
  await nextTick();
  const res = await useFetch(constants.BASE_URL + api.getBestSell,
    { method: 'GET' })
  console.log(res, 'list2');
  state.lists = res.data.value.data
}
getBestSell()
const prevImage = () => {
  if (boxref.value) {
    const currentScrollLeft = boxref.value.scrollLeft;
    boxref.value.scrollTo({
      left: currentScrollLeft - 400,
      behavior: 'smooth'
    });
  }
};

const nextImage = () => {
  if (boxref.value) {
    const currentScrollLeft = boxref.value.scrollLeft;
    boxref.value.scrollTo({
      left: currentScrollLeft + 400,
      behavior: 'smooth'
    });
  }
};
// console.log(props, lists.value, "props2222");

const goBanner = (item) => {
  console.log(item, 'item============');
  if (item?.clickUrl) {
    window.location.href = item.clickUrl
  } else {
    navigateTo('/product/'+ item.goodsCode)
  }
}
onUnmounted(() => {
});
</script>

<template>
  <div class="Module2" v-if="state?.lists">
    <div class="datitle">Best Sellers</div>
    <div class="m2-box">
      <div class="box" ref="boxref">
        <!-- <span style="color:red"> {{ state.lists }}</span> -->
        <div class="box-item" v-for="item in state?.lists" @click="goBanner(item)">
          <div class="box-item-b">
            <NuxtImg class="img" :src="item?.file?.fileUrl" alt="Carousel Image" />
            <div class="carousel-conntext">
              <div class="title">{{ item?.goodsName }}</div>
              <div class="describe">{{ item?.shortTitle }}</div>
              <div class="price">From USD ${{ item?.price }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="kzq" v-if="state?.lists.length > 4">
      <img src="../../assets/imgs/index/go1.png" @click="prevImage"></img>
      <img src="../../assets/imgs/index/go2.png" @click="nextImage"></img>
    </div>
  </div>
</template>

<style lang="less" scoped>
.Module2 {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  .datitle {
    margin: 100px 0 24px 160px;
    font-size: 36px;
    font-weight: 700;
    color: #ffffff;
  }

  /* 隐藏滚动条的通用样式 */
  ::-webkit-scrollbar {
    width: 0;
  }

  .m2-box {
    max-width: 1600px;
    margin: 0 auto;
    overflow: hidden;

    .box {
      width: 100%;
      height: 700px;
      position: relative;
      display: flex;
      overflow-x: auto;

      .box-item {
        margin-right: 24px;
        position: relative;
        cursor: pointer;
        .box-item-b {
          width: 458px;
          height: 570px;
          border-radius: 20px;
          overflow: hidden;
          position: relative;

          .img {
            width: 458px;
            height: 570px;
            border-radius: 20px;
            position: relative;
            z-index: 999;
          }

          .img:hover {
            transform: scale(1.1);
            transition: transform 0.3s ease;
          }

          .carousel-conntext {
            position: absolute;
            top: 74px;
            left: 35px;
            width: 389px;
            z-index: 1001;
            color: #ffffff;
            border-color: #ffffff;
            .title {
              font-size: 36px;
              font-weight: 700;
              color: #ffffff;
            }

            .describe {
              font-size: 20px;
              font-weight: 300;
              color: #ffffff;
              margin-top: 16px;
            }

            .price {
              font-size: 18px;
              font-weight: 500;
              color: #ffffff;
              margin-top: 16px;
            }
          }
        }

      }
    }
  }

  .kzq {
    position: absolute;
    bottom: 50%;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 24px;
    z-index: 99;
    cursor: pointer;
    img {
      width: 76px;
      height: 76px;
      line-height: 30px;
    }
  }
}
</style>
